import { Button, Form, Input, Modal, Radio, Select } from 'antd';
import { observer } from 'mobx-react';
import type React from 'react';
import { useEffect, useState } from 'react';
import { reasonDict } from '../dict';

interface IProps {
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (values: any) => void;
  [key: string]: any;
}
const Approval = observer(({ store }: { store: IProps }) => {
  const { visible, onClose, onSubmit, setRef, onChange, isHidden, isCheckAll } = store;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ status: '1' });
  }, [form]);
  console.log(store);
  return (
    <Modal
      title={isCheckAll ? '批量审核职位' : '审核职位'}
      open={visible}
      width="60%"
      centered={true}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Form
        ref={setRef}
        name="dynamic_form_item"
        layout="vertical"
        onFieldsChange={onChange}
      >
        <Form.Item
          name="status"
          label="是否通过"
          layout="horizontal"
          initialValue={'1'}
          rules={[{ required: true, message: '请选择审核结果' }]}
        >
          <Radio.Group>
            <Radio value="1">通过</Radio>
            <Radio value="2">不通过</Radio>
          </Radio.Group>
        </Form.Item>
        <div>
          <Form.Item
            name="auditReason"
            label="未通过原因"
            dependencies={['status']}
            hidden={isHidden}
            rules={[{ required: !isHidden, message: '请选择完善未通过原因' }]}
          >
            <ReasonChoose />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
});

const ReasonChoose = observer(({ value, onChange }: { value?: string; onChange?: (val: any) => any }) => {
  const [selectVal, selectSelectVal] = useState(value);
  const [inputVal, selectInputVal] = useState(value);

  const _onChange = (val) => {
    if (val?.target) {
      selectSelectVal(null);
      selectInputVal(val.target.value);
    } else {
      selectSelectVal(val);
      selectInputVal(null);
    }
    onChange(val);
  };

  return (
    <div>
      <Select
        value={selectVal}
        allowClear={true}
        options={reasonDict}
        fieldNames={{ value: 'label' }}
        onChange={_onChange}
        placeholder="请选择"
      />
      <Input.TextArea
        value={inputVal}
        placeholder="请输入"
        maxLength={30}
        onChange={_onChange}
        className="mb-3 mt-2"
      />
    </div>
  );
});

export { Approval };
