import type PositionManagementModel from '@/pages/positionManagement/model';
import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { isArray } from 'underscore';
import { API } from '../../api';
import type Model from '../model';

export default class ApprovalModel {
  @observable public visible = false;
  @observable public ref: FormInstance;
  @observable public positionIds: (string | number | null)[];

  @observable public isHidden = true;
  private isCheckAll: boolean = null; // 是否批量审核
  private isUnBind = false; // 是否未合作职位
  constructor(parentStore: Model | PositionManagementModel | any) {
    this.parentStore = parentStore;
  }
  public parentStore: Model | PositionManagementModel | any;
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  /**
   * 打开审核弹窗
   * @param ids
   * @param isUnBind 是否未合作职位
   */
  @action
  public onOpenApproveModal = (ids: number[], isUnBind = false) => {
    this.visible = true;
    if (ids.length > 1) {
      this.isCheckAll = true;
    } else {
      this.isCheckAll = false;
    }
    this.isUnBind = isUnBind;
    this.positionIds = [...ids];
  };
  // 关闭审核弹窗
  @action
  public onClose = () => {
    this.visible = false;
    this.visible = false;
    this.positionIds = null;
    this.isHidden = true;
    this.ref.resetFields();
  };

  @action
  public onSubmit = async () => {
    await this.ref.validateFields();
    const values = await this.ref.getFieldsValue();
    const url = this.isUnBind
      ? this.isCheckAll
        ? API.approvalAllPosition
        : API.approvalPosition
      : API.approvalPosition;
    await request<BaseData<any>>({
      url,
      method: 'POST',
      data: {
        positionIds: this.positionIds,
        ...values,
      },
    });
    message.success('操作成功');
    this.parentStore.onRefresh();
    this.onClose();
  };

  @action
  public onChange = (fields: any) => {
    const values = this.ref.getFieldsValue();
    this.isHidden = values.status !== '2';
  };
}
