export const jobTypeDict = [
  { label: '全职', value: '1' },
  { label: '长期兼职', value: '2' },
  { label: '临时兼职', value: '3' },
];
export const positionStatusDict = [
  { label: '已上线', value: '1' },
  { label: '待上线', value: '2' },
];
export const contactWXDict = [
  { label: '有', value: '1' },
  { label: '无', value: '0' },
];
export const bindStatusDict = [
  // { label: '已关联真实雇主', value: '1' },
  { label: '绑定虚拟雇主', value: '2' },
  { label: '未绑定', value: '0' },
];
export const auditStatusDict = [
  { label: '审核中', value: '0' },
  { label: '审核通过', value: '1' },
  { label: '审核失败', value: '2' },
];
export const showPartBrokerDict = [
  { label: '是', value: '1' },
  { label: '否', value: '0' },
];
export const anchorSexDict = [
  { label: '男', value: '1' },
  { label: '女', value: '2' },
];
export const acceptNewbieDict = [
  { label: '接受', value: '1' },
  { label: '不接受', value: '0' },
];
export const reasonDict = [
  { label: '亲，请按照平台要求，上传更为优质的职位宣传内容哟~', value: '1' },
  { label: '亲，请按照平台要求，上传与岗位相关联的职位宣传内容哟~', value: '2' },
  { label: '亲，请按照平台要求，核对薪资是否存在异常哟~', value: '3' },
  { label: '亲，请按照平台要求，核查公司工作性质是否与职位宣传内容一致哟~', value: '4' },
  { label: '亲，请按照平台要求，上传具体的工作地点哟~', value: '5' },
];
