import { type BaseData, request } from '@/utils';
import { Button, Form, type FormInstance, Modal, Radio, message } from 'antd';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

export class ShowToPartBrokerModel {
  @observable public visible = false;
  @observable public parent = null;
  @observable public ref: FormInstance;
  @observable public ids: [];
  constructor(parent) {
    this.parent = parent;
  }

  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  public onOpen = (ids) => {
    this.visible = true;
    this.ids = ids;
  };

  public onSubmit = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    const values = this.ref.getFieldsValue();

    await request<BaseData<any>>({
      method: 'post',
      url: '/pbb/platform/sys/position/showPositionToPartBrokerBatch',
      data: {
        positionIds: this.ids,
        showToPartBroker: values.showToPartBroker,
      },
    });

    this.onClose();
    message.success('操作成功');
    this.parent.mainStore.grid.onQuery();
  };
}

export const ShowToPartBroker = observer(({ store }: { store: ShowToPartBrokerModel }) => {
  const { visible, onSubmit, onClose, setRef } = store;
  return (
    <Modal
      open={visible}
      title="批量展示给兼职经纪人"
      footer={
        <div>
          <Button onClick={onClose}>取消</Button>
          <Button
            type="primary"
            onClick={onSubmit}
          >
            确定
          </Button>
        </div>
      }
      width={383}
    >
      <Form ref={setRef}>
        <Form.Item
          name="showToPartBroker"
          required={true}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
});
